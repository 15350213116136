






































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MSwitch from '@/shared/components/MSwitch.vue';

@Component({
  components: { MSwitch },
})
export default class PixelTrackingForm extends Vue {
  @Prop({}) isLoading: any;
  @Prop({}) value: any;

  displayFacebookTracking: boolean = false;
  displayGoogleAnalyticsTracking: boolean = false;
  displayGoogleAdsTracking: boolean = false;

  facebookPixel: string = '';
  facebookApiToken: string = '';
  gaTrackingId: string = '';
  googleAdsTrackingId: string = '';
  googleAdsTrackingLabel: string = '';

  hasOnlyFacebookPixelOnStart: boolean = false;
  enableFacebookInputs: boolean = false;

  get trackingData() {
    return {
      facebook_pixel: this.facebookPixel,
      facebook_api_token: this.facebookApiToken,
      ga_tracking_id: this.gaTrackingId,
      google_ads_tracking_id: this.googleAdsTrackingId,
      google_ads_tracking_label: this.googleAdsTrackingLabel,
    };
  }

  get mustShowGoogleField() {
    return this.$FCL.hasSparkCheckout();
  }

  emitInputData() {
    this.$emit('input', { ...this.value, ...this.trackingData });
  }

  checkFacebookInputsPresence() {
    const { facebookPixel, facebookApiToken, hasOnlyFacebookPixelOnStart } = this;

    if (hasOnlyFacebookPixelOnStart) return;

    const allInputsFilled = Boolean(facebookPixel && facebookApiToken);
    const noInputsFilled = Boolean(!facebookPixel && !facebookApiToken);

    this.enableFacebookInputs = Boolean(allInputsFilled || noInputsFilled);
  }

  mounted() {
    const {
      facebook_pixel,
      facebook_api_token,
      ga_tracking_id,
      google_ads_tracking_label,
      google_ads_tracking_id,
    } = this.value;

    if (facebook_pixel && !facebook_api_token) {
      this.hasOnlyFacebookPixelOnStart = true;
      this.enableFacebookInputs = true;
    }

    this.facebookPixel = facebook_pixel;
    this.facebookApiToken = facebook_api_token;
    this.gaTrackingId = ga_tracking_id;
    this.googleAdsTrackingId = google_ads_tracking_id;
    this.googleAdsTrackingLabel = google_ads_tracking_label;

    this.displayFacebookTracking = !!this.facebookPixel || !!this.facebookApiToken;
    this.displayGoogleAnalyticsTracking = !!this.gaTrackingId;
    this.displayGoogleAdsTracking = !!this.googleAdsTrackingId;

    this.checkFacebookInputsPresence();
  }

  @Watch('facebookPixel')
  onChangeFacebookPixel() {
    this.checkFacebookInputsPresence();
    this.emitInputData();
  }

  @Watch('facebookApiToken')
  onChangeFacebookApiToken() {
    this.checkFacebookInputsPresence();
    this.emitInputData();
  }

  @Watch('gaTrackingId')
  onChangeGaTrackingId() {
    this.emitInputData();
  }

  @Watch('googleAdsTrackingId')
  onChangeGoogleAdsTrackingId() {
    this.emitInputData();
  }

  @Watch('googleAdsTrackingLabel')
  onChangeGoogleAdsTrackingLabel() {
    this.emitInputData();
  }

  @Watch('displayFacebookTracking')
  onChangeDisplayFacebookTracking(displayFacebookTracking) {
    if (!displayFacebookTracking) {
      this.facebookPixel = '';
      this.facebookApiToken = '';
    }
  }

  @Watch('displayGoogleAnalyticsTracking')
  onChangeDisplayGoogleAnalyticsTracking(displayGoogleAnalyticsTracking) {
    if (!displayGoogleAnalyticsTracking) {
      this.gaTrackingId = '';
    }
  }

  @Watch('displayGoogleAdsTracking')
  onChangeDisplayGoogleAdsTracking(displayGoogleAdsTracking) {
    if (!displayGoogleAdsTracking) {
      this.googleAdsTrackingId = '';
      this.googleAdsTrackingLabel = '';
    }
  }

  @Watch('enableFacebookInputs')
  onChangeEnableFacebookInputs(stepEnableStatus) {
    this.$emit('save-enabled', {
      step: 'step-three',
      status: stepEnableStatus,
    });
  }
}
