


























import { Component, Prop, Vue } from 'vue-property-decorator';
import PixelTrackingForm from '../PixelTrackingForm.vue';

@Component({
  components: {
    PixelTrackingForm,
  },
})
export default class OfferTabsStepThree extends Vue {
  @Prop({}) isLoading: any;
  @Prop({}) value: any;

  emitInputData(inputData) {
    this.$emit('input', inputData);
  }

  emitSaveEnabled(enabled) {
    this.$emit('save-enabled', enabled);
  }
}
